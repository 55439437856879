const uniqueLanguageKey = (string) => {
    return string ? string.trim().toLowerCase().replace(/[^a-z0-9_\s]/g,' ').trim().replaceAll(' ','_').trim() : ''
}
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const capitalizeLetterFoEachWord = (string) => {
    const words = string.split(" ");
    return words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    }).join(" ");
}
const convertToFormData = (data) => {
    let formData = new FormData();
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            if (data[key] || data[key] !== null) {
                formData.append(key, data[key]);
            }

        }

    }
    return formData
}
export {capitalizeFirstLetter, convertToFormData,uniqueLanguageKey,capitalizeLetterFoEachWord}
