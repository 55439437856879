import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from 'redux-thunk';
import './assets/scss/style.scss';
import userReducer from "./store/User/reducer";
import userPermissionReducer from "./store/User/Permission/reducer";
import userRoleReducer from "./store/User/Role/reducer";
import eoSettingReducer from "./store/EO/EoSetting/reducer";
import glossaryReducer from "./store/Glossary/reducer";
import ourPartnerReducer from "./store/OurPartner/reducer";
import feedbackReducer from "./store/Feedback/reducer";

const middleware = [thunk];
const initialState = {};
const rootReducer = combineReducers({
    menu: reducer,
    user: userReducer,
    userRole: userRoleReducer,
    userPermission: userPermissionReducer,
    eoSetting: eoSettingReducer,
    glossary: glossaryReducer,
    ourPartner: ourPartnerReducer,
    feedback: feedbackReducer,
});
const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

const app = (
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
