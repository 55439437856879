import HttpService from "./HttpService";

class FeedbackHttpService extends HttpService {
    constructor() {
        super();
        this.url = this.url + "/feedbacks";
    }


    /**
     * Fetch all with paginate
     * @returns {Promise<unknown>}
     */
    findAllWithPaginate(params) {
        return new Promise(async (resolve, reject) => {
            try {
                const source = this.createSource();
                let resp = await this.axiosHttp.get(this.url, { params,  cancelToken: source.token});
                resolve(resp.data);
            } catch (e) {
                reject(e);
            }
        });
    }

}

export default new FeedbackHttpService();
