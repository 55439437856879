import HttpService from "./HttpService";

class UserRoleHttpService extends HttpService {
    constructor() {
        super();
        this.url = this.url + "/roles";
    }


}

export default new UserRoleHttpService();
