import GlossaryHttpService from './../../App/services/GlossaryHttpService';
import {stringToTimestampWithPeriod} from "../../App/utils/date";

export const FETCH_ALL_GLOSSARY = 'FETCH_ALL_GLOSSARY';
export const FETCH_ONE_GLOSSARY = 'FETCH_ONE_GLOSSARY';
export const CREATE_GLOSSARY = 'CREATE_GLOSSARY';
export const UPDATE_GLOSSARY = 'UPDATE_GLOSSARY';
export const DELETE_GLOSSARY = 'DELETE_GLOSSARY';

export const fetchAll = () => async (dispatch) => {
    try {
        const resp = await GlossaryHttpService.findAll();
        resp.data = resp.data.map(data => {
            return {...data, updated_at: stringToTimestampWithPeriod(data.updated_at)}
        })
        if (resp.data) await dispatch({type: FETCH_ALL_GLOSSARY, payload: resp.data,})
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const fetchOne = (id) => async (dispatch) => {
    try {
        // const resp = await GlossaryHttpService.findOne(id);
        await dispatch({type: FETCH_ONE_GLOSSARY, payload: {id: id},});
        // return resp?.data;
    } catch (err) {
        throw err
    }
};


export const create = (data) => async (dispatch) => {
    try {
        const resp = await GlossaryHttpService.create(data);
        if (resp.data) await dispatch({type: CREATE_GLOSSARY, payload: resp.data});
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const update = (id, data) => async (dispatch) => {
    try {
        const resp = await GlossaryHttpService.create(data);
        if (resp.data) await dispatch({type: UPDATE_GLOSSARY, payload: {...resp.data, id},});

        return resp?.message;
    } catch (err) {
        throw err
    }
};
export const remove = (id) => async (dispatch) => {
    try {
        const resp = await GlossaryHttpService.delete(id);
        await dispatch({type: DELETE_GLOSSARY, payload: {id: id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
