import UserPermissionHttpService from './../../../App/services/UserPermissionHttpService';
import {stringToTimestampWithPeriod} from "../../../App/utils/date";

export const FETCH_ALL_USER_PERMISSION = 'FETCH_ALL_USER_PERMISSION';
export const FETCH_ONE_USER_PERMISSION = 'FETCH_ONE_USER_PERMISSION';
export const CREATE_USER_PERMISSION = 'CREATE_USER_PERMISSION';
export const UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION';
export const DELETE_USER_PERMISSION = 'DELETE_USER_PERMISSION';

export const fetchAll = () => async (dispatch) => {
    try {
        const resp = await UserPermissionHttpService.findAll();
        resp.data = resp.data.map(data => {
            return {...data, updated_at: stringToTimestampWithPeriod(data.updated_at)}
        })
        if (resp.data) await dispatch({type: FETCH_ALL_USER_PERMISSION, payload: resp.data,})
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const fetchOne = (id) => async (dispatch) => {
    try {
        const resp = await UserPermissionHttpService.findOne(id);
        await dispatch({type: FETCH_ONE_USER_PERMISSION, payload: {id: id},});
        return resp?.data;
    } catch (err) {
        throw err
    }
};


export const create = (data) => async (dispatch) => {
    try {
        const resp = await UserPermissionHttpService.create(data);
        if (resp.data) await dispatch({type: CREATE_USER_PERMISSION, payload: resp.data,});
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const update = (id, data) => async (dispatch) => {
    try {
        const resp = await UserPermissionHttpService.update(id, data);
        if (resp.data) await dispatch({type: UPDATE_USER_PERMISSION, payload: {...data, id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
export const remove = (id) => async (dispatch) => {
    try {
        const resp = await UserPermissionHttpService.delete(id);
        await dispatch({type: DELETE_USER_PERMISSION, payload: {id: id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
