import UserHttpService from './../../App/services/UserHttpService';
import {stringToTimestampWithPeriod} from "../../App/utils/date";

export const FETCH_ALL_USER = 'FETCH_ALL_USER';
export const FETCH_ONE_USER = 'FETCH_ONE_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const fetchAll = () => async (dispatch) => {
    try {
        const resp = await UserHttpService.findAll();
        resp.data=resp.data.map(data=>{
            return {...data,updated_at:stringToTimestampWithPeriod(data.updated_at)}
        })

        if(resp.data) await dispatch({type: FETCH_ALL_USER, payload: resp.data})
        return resp?.data;
    } catch (err) {
        throw err
    }
};

export const fetchOne = (id) => async (dispatch) => {
    try {
        const resp = await UserHttpService.findOne(id);
        if(resp.data) await dispatch({type: FETCH_ONE_USER, payload:{id:id},});
        return resp?.data;
    } catch (err) {
        throw err
    }
};


export const create = (data) => async (dispatch) => {
    try {
        const resp = await UserHttpService.create(data);
        if(resp.data) await dispatch({type: CREATE_USER, payload: resp.data});
        return resp?.data;
    } catch (err) {
        throw err
    }
};

export const update = (id,data) => async (dispatch) => {
    try {
        const resp = await UserHttpService.update(id,data);
        if(resp.data) await dispatch({type: UPDATE_USER, payload: {...resp.data,id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
export const remove = (id) => async (dispatch) => {
    try {
        const resp = await UserHttpService.delete(id);
        await dispatch({type: DELETE_USER, payload:{id:id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
