import moment from "moment";

const formatDate = "YYYY-MM-DD";
const formatTimestamp = "YYYY-MM-DD h:mm:ss";
const formatTimestampWithPeriod = "YYYY-MM-DD h:mm:ss a";

/**
 * get current date
 * @returns {string}
 */
const getCurrentDate = () => {
    const currentDate = new Date();
    return getMomentDateString(currentDate, formatDate);
};

/**
 * get current timestamp
 * @returns {string}
 */
const getCurrentTimestamp = () => {
    const currentDate = new Date();
    return getMomentDateString(currentDate, formatTimestamp);
};
/**
 * string to timestamp
 * @param string
 * @returns {string}
 */
const stringToTimestampWithPeriod = (string) => {
    return getMomentDateString(string, formatTimestampWithPeriod);
};

/**
 * number to timestamp
 * @param number
 * @returns {string}
 */
const numberToTimestampWithPeriod = (number) => {
    return getMomentDateString(number, formatTimestampWithPeriod);
};


/**
 * number to timestamp
 * @param number
 * @returns {*|string}
 */
const numberToTimestamp = (number) => {
    return getMomentDateString(number, formatTimestamp);
};

/**
 * number to date
 * @param number
 * @returns {*|string}
 */
const numberToDate = (number) => {
    return getMomentDateString(number, formatDate);
};
/**
 * get date from value and format
 * @param dateValue
 * @param format
 * @returns {*|string}
 */
const getMomentDateString = (dateValue, format) => {
    return dateValue === null || dateValue === ""
        ? dateValue
        : // : isCorrectFormat(dateValue, format)
          //   ? dateValue
        moment(dateValue).format(format);
};


const checkDateValidate = (date) => {
    return moment(date, formatDate).isValid();
};

export {
    getMomentDateString,
    getCurrentDate,
    getCurrentTimestamp,
    stringToTimestampWithPeriod,
    numberToTimestamp,
    numberToTimestampWithPeriod,
    numberToDate,
    checkDateValidate,
};
