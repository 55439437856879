import HttpService from "./HttpService";
import {convertToFormData} from "../utils";

class OurPartnerHttpService extends HttpService {
    constructor() {
        super();
        this.url = this.url + "/eo/partners";
    }

    update(id, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let formData = convertToFormData(data);
                formData.append("_method", "put");
                let resp = await this.axiosHttp.post(this.url+'/'+id, formData, this.requestAdditionalConfig());
                resolve(resp.data)
            } catch (e) {
                reject(e)
            }
        })
    }

    create(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let formData = convertToFormData(data);
                let resp = await this.axiosHttp.post(this.url, formData, this.requestAdditionalConfig());
                resolve(resp.data)
            } catch (e) {
                reject(e)
            }
        })
    }

}

export default new OurPartnerHttpService();
