import HttpService from "./HttpService";


class UserPermissionHttpService extends HttpService {
    constructor() {
        super();
        this.url = this.url + "/permissions";
    }


}

export default new UserPermissionHttpService();
