import React from 'react';

const SignIn = React.lazy(() => import('./pages/Authentication/SignIn/SignIn1'));
const ResetPassword = React.lazy(() => import('./pages/Authentication/ResetPassword/ResetPassword1'));
const ChangePassword = React.lazy(() => import('./pages/Authentication/ChangePassword'));

const route = [
    {path: '/auth/signin', exact: true, name: 'Signin 1', component: SignIn},
    {path: '/auth/reset-password', exact: true, name: 'Reset Password ', component: ResetPassword},
    {path: '/auth/change-password', exact: true, name: 'Change Password ', component: ChangePassword},
    { path: '/pages/privacy-policy', exact: true, name: 'Privacy Policy for Hi-eo ', component: React.lazy(() => import('./pages/PrivacyPolicy')) },
];

export default route;
