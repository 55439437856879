import EoSettingHttpService from './../../../App/services/EO/EoSettingHttpService';
import {stringToTimestampWithPeriod} from "../../../App/utils/date";

export const FETCH_ALL_EO_SETTING = 'FETCH_ALL_EO_SETTING';
export const FETCH_ONE_EO_SETTING = 'FETCH_ONE_EO_SETTING';
export const CREATE_EO_SETTING = 'CREATE_EO_SETTING';
export const UPDATE_EO_SETTING = 'UPDATE_EO_SETTING';
export const DELETE_EO_SETTING = 'DELETE_EO_SETTING';

export const fetchAll = () => async (dispatch) => {
    try {
        const resp = await EoSettingHttpService.findAll();
        resp.data = resp.data.map(data => {
            return {...data, updated_at: stringToTimestampWithPeriod(data.updated_at)}
        })
        if (resp.data) await dispatch({type: FETCH_ALL_EO_SETTING, payload: resp.data,})
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const fetchOne = (id) => async (dispatch) => {
    try {
        const resp = await EoSettingHttpService.findOne(id);
        await dispatch({type: FETCH_ONE_EO_SETTING, payload: {id: id},});
        return resp?.data;
    } catch (err) {
        throw err
    }
};


export const create = (data) => async (dispatch) => {
    try {
        const resp = await EoSettingHttpService.create(data);
        if (resp.data) await dispatch({type: CREATE_EO_SETTING, payload: resp.data});
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const update = (id, data) => async (dispatch) => {
    try {
        const resp = await EoSettingHttpService.update(id, data);
        if (resp.data) await dispatch({type: UPDATE_EO_SETTING, payload: {...resp.data, id},});

        return resp?.message;
    } catch (err) {
        throw err
    }
};
export const remove = (id) => async (dispatch) => {
    try {
        const resp = await EoSettingHttpService.delete(id);
        await dispatch({type: DELETE_EO_SETTING, payload: {id: id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
