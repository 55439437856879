import {DELETE_FEEDBACK, FETCH_ALL_FEEDBACK, FETCH_ONE_FEEDBACK} from './actions'

const initialState = {
    meta: {
        page: 1,
        limit: 10,
        total_rows: 0
    },
    results: [],
    row: {}
};

function feedbackReducer(state = initialState, action) {

    const {type, payload} = action;
    switch (type) {
        case FETCH_ALL_FEEDBACK:
            return {...state, results: payload.rows, meta: payload.meta, row: {}};
        case FETCH_ONE_FEEDBACK:
            return {...state, row: state.results.find(({id}) => id === payload.id)};
        case DELETE_FEEDBACK:
            state.results = state.results.filter(({id}) => id !== payload.id);
            return {...state, row: {}};
        default:
            return {...state, row: {}};
    }
}

export default feedbackReducer;
