import HttpService from "./HttpService";

class GlossaryHttpService extends HttpService {
    constructor() {
        super();
        this.url = this.url + "/eo/glossary";
    }
}

export default new GlossaryHttpService();
