import Axios from "axios";
import Cookies from "universal-cookie";
import config from "../../config";

const cookies = new Cookies();

class HttpService {
    constructor() {
        this.baseUrl = config.apiURL + "/api/v1";
        this.url = this.baseUrl
        this.axiosHttp = Axios.create();
        this.addHeadersToRequestHttp();
        this.source = null;
    }

//Source for Axios request to cancel them when new request is created
    createSource = () => {
        if (this.source !== null) this.source.cancel("Operation canceled by the user.");
        const CancelToken = Axios.CancelToken;
        this.source = CancelToken.source();
        return this.source;
    };

    /**
     * add required headers to request http
     */
    addHeadersToRequestHttp() {
        // Request interceptor for API calls
        this.axiosHttp.interceptors.request.use(
            (config) => {
                config.headers = {
                    Authorization: `Bearer ${cookies.get("access_token")}`,
                    Accept: "application/json",
                };
                return config;
            },
            (error) => {
                console.log(error)
                Promise.reject(error);
            }
        );
        // Add a response interceptor
        this.axiosHttp.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            if (error.response?.status === 401) {
                cookies.remove('loggedIn');
                cookies.remove('permission');
                cookies.remove('access_token');
                cookies.remove('expires_at');
                cookies.remove('expires_at');

                document.cookie.split(";").forEach(function (c) {
                    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                });
                window.location.href = 'auth/signin'
            }
            return Promise.reject(error);
        });
    }

    requestAdditionalConfig() {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
    }

    /**
     * Fetch all
     * @returns {Promise<unknown>}
     */
    findAll() {

        return new Promise(async (resolve, reject) => {
            try {
                const source = this.createSource();

                let resp = await this.axiosHttp.get(this.url,{
                    cancelToken: source.token,
                })
                resolve(resp.data)
            } catch (e) {
                console.log(e)
                reject(e)
            }
        })

    }

    /**
     * Create
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create(data) {
        return new Promise(async (resolve, reject) => {
            try {
                let resp = await this.axiosHttp.post(this.url, data);
                resolve(resp.data)
            } catch (e) {
                reject(e)
            }
        })

    }

    /**
     * Update
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    update(id, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let resp = await this.axiosHttp.put(this.url + "/" + id, data);
                resolve(resp.data)
            } catch (e) {
                reject(e)
            }
        })

    }

    /**
     * Fetch row
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    findOne(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const source = this.createSource();
                let resp = await this.axiosHttp.get(this.url + "/" + id,{
                    cancelToken: source.token,
                });
                resolve(resp.data)
            } catch (e) {
                reject(e)
            }
        })


    }

    /**
     * Delete
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let resp = await this.axiosHttp.delete(this.url + "/" + id);
                resolve(resp.data)
            } catch (e) {
                reject(e)
            }
        })

    }
    /**
     * Change status
     * @param id
     * @param status
     * @returns {Promise<AxiosResponse<any>>}
     */
    changeStatus(id,status) {
        return new Promise(async (resolve, reject) => {
            try {
                let resp = await this.axiosHttp.post(this.baseUrl + "/user-status",{
                    id:id,
                    is_active:status,
                });
                resolve(resp.data)
            } catch (e) {
                reject(e)
            }
        })


    }

}

export default HttpService;
