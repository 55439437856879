import {CREATE_EO_SETTING, DELETE_EO_SETTING, FETCH_ALL_EO_SETTING, FETCH_ONE_EO_SETTING, UPDATE_EO_SETTING} from './actions'

const initialState = {
    results: [],
    row: {}
};

function eoSettingReducer(state = initialState, action) {

    const {type, payload} = action;
    switch (type) {
        case CREATE_EO_SETTING:
            state.results = [...state.results, payload];
            return {...state};
        case FETCH_ALL_EO_SETTING:
            return {...state,results: payload,row: {}};
        case FETCH_ONE_EO_SETTING:
            return {...state, row: state.results.find(({id}) => id === payload.id)};
        case UPDATE_EO_SETTING:
            state.results = state.results.map((data) => {
                if (data.id === payload.id) data = payload;
                return data;
            });
            return {...state, row: {}};
        case DELETE_EO_SETTING:
            state.results = state.results.filter(({id}) => id !== payload.id);
            return {...state, row: {}};
        default:
            return {...state, row: {}};
    }
}

export default eoSettingReducer;
