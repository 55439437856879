import HttpService from "./HttpService";


class UserHttpService extends HttpService {
    constructor() {
        super();
        this.url = this.url + "/users";
    }
}

export default new UserHttpService();
