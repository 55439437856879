import OurPartnerHttpService from './../../App/services/OurPartnerHttpService';
import {stringToTimestampWithPeriod} from "../../App/utils/date";

export const FETCH_ALL_OUR_PARTNER = 'FETCH_ALL_OUR_PARTNER';
export const FETCH_ONE_OUR_PARTNER = 'FETCH_ONE_OUR_PARTNER';
export const CREATE_OUR_PARTNER = 'CREATE_OUR_PARTNER';
export const UPDATE_OUR_PARTNER = 'UPDATE_OUR_PARTNER';
export const DELETE_OUR_PARTNER = 'DELETE_OUR_PARTNER';

export const fetchAll = () => async (dispatch) => {
    try {
        const resp = await OurPartnerHttpService.findAll();
        resp.data = resp.data.map(data => {
            return {...data, updated_at: stringToTimestampWithPeriod(data.updated_at)}
        })
        if (resp.data) await dispatch({type: FETCH_ALL_OUR_PARTNER, payload: resp.data,})
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const fetchOne = (id) => async (dispatch) => {
    try {
        // const resp = await OurPartnerHttpService.findOne(id);
        await dispatch({type: FETCH_ONE_OUR_PARTNER, payload: {id: id},});
        // return resp?.data;
    } catch (err) {
        throw err
    }
};


export const create = (data) => async (dispatch) => {
    try {
        const resp = await OurPartnerHttpService.create(data);
        if (resp.data) await dispatch({type: CREATE_OUR_PARTNER, payload: resp.data});
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const update = (id, data) => async (dispatch) => {
    try {
        const resp = await OurPartnerHttpService.create(data);
        if (resp.data) await dispatch({type: UPDATE_OUR_PARTNER, payload: {...resp.data, id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
export const remove = (id) => async (dispatch) => {
    try {
        const resp = await OurPartnerHttpService.delete(id);
        await dispatch({type: DELETE_OUR_PARTNER, payload: {id: id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
