import FeedbackHttpService from '../../App/services/FeedbackHttpService';

export const FETCH_ALL_FEEDBACK = 'FETCH_ALL_FEEDBACK';
export const FETCH_ONE_FEEDBACK = 'FETCH_ONE_FEEDBACK';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';

export const fetchAll = (params) => async (dispatch) => {
    try {
        const resp = await FeedbackHttpService.findAllWithPaginate(params);
        if (resp.data) await dispatch({type: FETCH_ALL_FEEDBACK, payload: resp.data})
        return resp.data;
    } catch (err) {
        throw err
    }
};

export const fetchOne = (id) => async (dispatch) => {
    try {
        // const resp = await FeedbackHttpService.findOne(id);
        await dispatch({type: FETCH_ONE_FEEDBACK, payload: {id: id},});
        // return resp?.data;
    } catch (err) {
        throw err
    }
};


export const remove = (id) => async (dispatch) => {
    try {
        const resp = await FeedbackHttpService.delete(id);
        await dispatch({type: DELETE_FEEDBACK, payload: {id: id},});
        return resp?.message;
    } catch (err) {
        throw err
    }
};
